import React from "react"
import PageTemplate from "../../../templates/pageTemplate.jsx"
import ArticleTemplate from "../../../templates/articleTemplate.jsx"
import { useQuery } from "react-query"
import axios from "axios"

const PagePreviewTemplate = ({ params, location }) => {
  const { isLoading, isError, data, error } = useQuery(
    location.search,
    async () => {
      const response = await axios.get(
        `/api/collections/${params.type}/entries/${params.id}${location.search}`,
        {
          headers: {
            "X-Statamic-Live-Preview": true,
          },
        }
      )
      const data = {
        ...response.data.data,
        header: response.data.data?.header?.map(content => ({
          ...content,
          __typename:
            content.type.charAt(0).toUpperCase() + content.type.slice(1),
        })),
        content: response.data.data?.content?.map(content => ({
          ...content,
          __typename:
            content.type.charAt(0).toUpperCase() + content.type.slice(1),
        })),
      }

      return {
        data: {
          [`collection${
            params.type.charAt(0).toUpperCase() + params.type.slice(1)
          }`]: data,
        },
        pageContext: {
          locale: data.locale,
          translations: [],
        },
      }
    }
  )

  if (isLoading) {
    return "...chargement"
  }

  if (isError) {
    console.error(error)
    return "erreur de chargement"
  }

  switch (params.type) {
    case "articles":
      return <ArticleTemplate data={data.data} pageContext={data.pageContext} />
    case "pages":
      return <PageTemplate data={data.data} pageContext={data.pageContext} />
    default:
      return "Il n'y a pas de prévisualition pour cette collection."
  }
}

export default PagePreviewTemplate
